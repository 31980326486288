class TopicTimeTool {
  constructor() {}

  static setStartDate() {
    const aValue = App.Topic.valuesPresent[0];
    const timeStamps = App.Topic.processedMessages[aValue]['reported'].timeStamps;
    if (timeStamps.length > 0) {
      App.Topic.startDate = timeStamps[0]; //first message time
    }
  }

  static setStartDatePicker() {
    $("#start-time").data({
      date: moment(App.Topic.startDate)
    });
    $("#start-time").datetimepicker("update");
    $("#start-time")
      .datetimepicker()
      .children("input")
      .val(moment(App.Topic.startDate).format("YYYY-MM-DD, H:mm:ss"));
  }

  static setEndDatePicker() {
    $("#end-time").data({
      date: moment(App.Topic.endDate)
    });
    $("#end-time").datetimepicker("update");
    $("#end-time")
      .datetimepicker()
      .children("input")
      .val(moment(App.Topic.endDate).format("YYYY-MM-DD, H:mm:ss"));
  }

  static setEndDate() {
    const aValue = App.Topic.valuesPresent[0];
    const timeStamps = App.Topic.processedMessages[aValue]['reported'].timeStamps;
    App.Topic.endDate =
      timeStamps[timeStamps.length - 1];
  }
}
export default TopicTimeTool;
