"use strict";

class MessageProcessor {
  static initialiseProcessedMessages(sensorName) {
    if (App.Topic.processedMessages[sensorName] == undefined) {
      App.Topic.processedMessages[sensorName] = {}; //initialise array for messages for this value
    }
  }

  static initialiseDataInterpretation(sensorName, dataInterpretation) {
    if (App.Topic.processedMessages[sensorName][dataInterpretation] === undefined) {
      App.Topic.processedMessages[sensorName][dataInterpretation] = {
        timeStamps: [],
        readings: {},
        average: undefined,
      };
    }
  }

  static processMessages(rawMessages = App.Topic.allMessages) {
    const topicInterval = App.Topic.interval;

    //Initialisation
    App.Topic.valuesPresent.forEach(function (sensorName) {      
      MessageProcessor.initialiseProcessedMessages(sensorName);

      const dataInterpretations = App.Topic.dataInterpretations;
      for (let i = 0; i < dataInterpretations.length; i++) {
        const dataInterpretation = dataInterpretations[i];
        MessageProcessor.initialiseDataInterpretation(
          sensorName,
          dataInterpretation
        );

        var messages = {
          timeStamps: [],
          readings: {},
          average: undefined,
          averages: {},
        };
        //Loop through the raw messages
        for (let ii = 0; ii < rawMessages.length; ii++) {
          //Load a message from the raw messages
          const json = rawMessages[ii];

          //Process the message
          if (MessageValue.valueFor(sensorName, json, dataInterpretation) != undefined) {
            MessageProcessor.processMessage(messages, json, dataInterpretation, sensorName, topicInterval);
          }
        }
        //All messages have been processed
        //Now append local collection to global processed messages collection
        if (
          App.Topic.processedMessages[sensorName][dataInterpretation].timeStamps
            .length > 0
        ) {
          // Timestamps
          App.Topic.processedMessages[sensorName][
            dataInterpretation
          ].timeStamps = App.Topic.processedMessages[sensorName][
            dataInterpretation
          ].timeStamps.concat(messages.timeStamps);
          //Readings
          Object.assign(
            App.Topic.processedMessages[sensorName][dataInterpretation]
              .readings,
            messages.readings
          );
          //Readings
          Object.assign(
            App.Topic.processedMessages[sensorName][dataInterpretation]
              .averages,
            messages.averages
          );
        } else {
          App.Topic.processedMessages[sensorName][
            dataInterpretation
          ].timeStamps = messages.timeStamps;
          App.Topic.processedMessages[sensorName][dataInterpretation].readings =
            messages.readings;
          App.Topic.processedMessages[sensorName][dataInterpretation].averages =
            messages.averages;
        }
        MessageProcessor.calculateAverage(sensorName, dataInterpretation);
      }
    });
  }

  static processMessage(messages, json, dataInterpretation, sensorName, topicInterval) {
    if (messages.timeStamps.length == 0) {
      const firstMessage = MessageProcessor.buildProcessedMessage(
        json,
        sensorName,
        dataInterpretation
      );

      if (firstMessage == undefined) {
        console.log("Unable to process this message.");
        console.log(json);
        return false;
      }
      messages.averages[firstMessage[0].toISOString()] = null;
      MessageProcessor.addMessageToCollection(firstMessage, messages);
    } else if (
      messages.timeStamps[messages.timeStamps.length - 1] != undefined
    ) {
      //surely this means is there a message before this one?

      let lastMessageTimestamp =
        messages.timeStamps[messages.timeStamps.length - 1];
      let messageInterval = MessageProcessor.messageTimeDifference(
        lastMessageTimestamp,
        json.rec
      );
      //if a gap is detected then the processed messages array is padded.
      if (messageInterval > 2 * topicInterval) {
        messages = MessageProcessor.padMessages(
          messages,
          topicInterval,
          Math.round(messageInterval / App.Topic.valueInterval),
          sensorName
        );
        //And calculate the average for the segment just finished
      }

      //Make sure next message is in the future
      if (
        new Date(json.rec).getTime() >
        new Date(lastMessageTimestamp).getTime()
      ) {
        let nextMessage = MessageProcessor.buildProcessedMessage(
          json,
          sensorName,
          dataInterpretation
        );
        messages.averages[nextMessage[0].toISOString()] = null;
        MessageProcessor.addMessageToCollection(nextMessage, messages);
      } else {
        console.log("Next message is in the past. Skipping.");
      }
    }
  }

  static buildProcessedMessage(message, value, dataInterpretation) {
    // var timeStamp = new Date(
    //   moment(message.rec, App.Topic.messageTimeFormatString)
    // );
    var timeStamp = new Date(message.rec);

    if (App.Topic.firstMessageTimeRecieved == null) {
      App.Topic.firstMessageTimeRecieved = timeStamp;
    }
    //These are used for the Gauges
    MessageCollections.messagesWithTimestamps(
      value,
      message,
      dataInterpretation
    );

    App.Topic.lastMessageTimeRecieved = timeStamp;

    var sensorValue = MessageValue.valueFor(value, message, dataInterpretation);
    if (typeof sensorValue == "number") {
      return [timeStamp, sensorValue];
    } else if (sensorValue == null) {
      return [timeStamp, null];
    } else {
      return [timeStamp, sensorValue[0], sensorValue[1]];
    }
  }

  static addMessageToCollection(message, collection) {
    const timestamp = message[0].toISOString();
    collection.timeStamps.push(timestamp);
    if (message.length == 3) {
      collection.readings[timestamp] = [message[1], message[2]];
    } else {
      collection.readings[timestamp] = message[1];
    }
  }

  static messageTimeDifference(message1, message2) {
    if (message1 == undefined || message2 == undefined) {
      return 0;
    }
    // let difference = Math.round(
    //   moment(message2, App.Topic.messageTimeFormatString).diff(
    //     moment(message1, App.Topic.messageTimeFormatString)
    //   ) / 1000
    // );
    let difference = Math.round(
      (new Date(message2).getTime() - new Date(message1).getTime()) / 1000
    );    
    return difference;
  }

  static padMessages(messages, intervalSeconds, howMany, sensorName) {
    var timeStamp = messages.timeStamps[messages.timeStamps.length - 1];
    for (var i = 0; i < howMany; i++) {
      let nextTimeStamp = moment(timeStamp).add(intervalSeconds, "s");
      messages.timeStamps.push(nextTimeStamp);
      if (sensorName == "sht" || sensorName == "psu") {
        messages.readings[nextTimeStamp] = [null, null];
      } else {
        messages.readings[nextTimeStamp] = null;
      }
    }
    return messages;
  }

  static getMinValueFor(value, dataInterpretation) {
    let values = Object.values(
      App.Topic.processedMessages[value][dataInterpretation].readings
    );
    return Math.min.apply(null, values) - 10;
  }

  static calculateMaxValue(sensorName, dataInterpretation, readings) {
    App.Topic.processedMessages[sensorName][
      dataInterpretation
    ].maxReading = Math.max.apply(null, readings);
  }

  static calculateAverage(sensorName, dataInterpretation) {
    //need to detect gaps and then write to the averages hash
    let readings =
      App.Topic.processedMessages[sensorName][dataInterpretation].readings;
    let times =
      App.Topic.processedMessages[sensorName][dataInterpretation].timeStamps;
    let averages =
      App.Topic.processedMessages[sensorName][dataInterpretation].averages;

    let currentSegment = { sum: 0, counter: 0, times: [] };
    let average = null;

    for (let i = 0; i < times.length; i++) {
      if (readings[times[i]] != null) {
        currentSegment.sum += readings[times[i]];
        currentSegment.counter++;
        currentSegment.times.push(times[i]);
      } else {
        if (currentSegment.times.length == 0) {
          // this is a gap
          continue;
        }
        average = currentSegment.sum / currentSegment.counter;

        //set all times to this new average value
        for (let ii = 0; ii < currentSegment.times.length; ii++) {
          averages[currentSegment.times[ii]] = average;
        }
        currentSegment.sum = 0;
        currentSegment.counter = 0;
        currentSegment.times = [];
      }
    }
    //reached the end of messages and no gaps found
    if (currentSegment.times.length > 0) {
      average = currentSegment.sum / currentSegment.counter;
      for (let ii = 0; ii < currentSegment.times.length; ii++) {
        averages[currentSegment.times[ii]] = average;
      }
    }
  }
}

export default MessageProcessor;
