"use strict";

class Json2Csv {
  constructor(checkpoint) {
    this.headers = [];
    this.checkpoint = checkpoint;
    App.Topic.allMessages = [];
    this.timeout = ScsConfig().connectionTimeout;
    this.topic = document.querySelector(".data").getAttribute("data-topic");
  }

  getHeaders(json, headerPrefix = "") {
    Object.keys(json).forEach(key => {
      if (typeof json[key] == "object" && json[key] != undefined) {
        //check to see if its an object or an array HERE
        if (json[key].constructor == Array) {
          this.getHeaders(json[key], `${headerPrefix}${key}:`);
        } else if (json[key].constructor == Object) {
          this.getHeaders(json[key], `${headerPrefix}${key}.`);
        }
      } else if (key == undefined) {
        console.log(`got a bad key ${key}`);
      } else {
        this.headers.push(headerPrefix + key);
      }
    });
  }

  buildFilename(message, startTime, endTime) {
    const topic = this.topic;
    const lastBit = topic.split("/").pop();
    //const tag = message.payload.tag;
    const tag = message.tag;

    const removeMillisecondsRegex = /\.(\d{3})/;
    const removeSemiColonsRegex = /:/g;
    startTime = startTime
      .replace(removeSemiColonsRegex, "-")
      .replace(removeMillisecondsRegex, "");
    endTime = endTime
      .replace(removeSemiColonsRegex, "-")
      .replace(removeMillisecondsRegex, "");

    this.fileName = `${tag}_${lastBit}_${startTime}_${endTime}${this.aggregationFileSuffix()}.csv`;
  }

  aggregationFileSuffix(){
    const mappings =
    {
      "none": "",
      "**:**:00":   "_1min",
      "**:/15:00":  "_15min",
      "**:00:00":   "_1hr",
      "/6:00:00":   "_6hr"
    }
    return mappings[this.checkpoint];
  }

  readData(message) {
    let row = "";

    this.headers.forEach(header => {
      //convert header to array key
      let replacedHeader = header.replace(":", "."); //some headers have : in them
      let keys = replacedHeader.split(".");

      let value = message;
      
      try {
        keys.forEach(key => {
          value = value[key];
          if (
            typeof value == "string" ||
            typeof value == "number" ||
            typeof value == "boolean"
          ) {
            row = row + value + ",";
          } else if ( value == undefined || value == null){
            row = row + "" + ",";
          }
        });
      } catch (e) {
        console.log("caught an error");
        console.log(e);
      }
    });
    row = row.slice(0, -1); //remove trainling , character
    return row;
  }

  headerToKey(header) {
    //header is a string . delimited
    let split = header.split(".");
    let key = "";
    split.forEach(keyPart => {
      key = key + [keyPart];
    });
  }

  csvHeaders() {
    return this.headers + "\n";
  }

  genereateCsvRows(data) {
    let rows = "";

    data.forEach(message => {
      //let messageCsvRow = this.readData(message.payload);
      let messageCsvRow = this.readData(message);
      rows = rows + messageCsvRow + "\n";
    });

    return rows;
  }

  generateCsv(data) {
    let csv = "";
    //this.getHeaders(data[0].payload); //discover headers
    this.getHeaders(data[0]); //discover headers
    csv = csv + this.csvHeaders();
    csv = csv + this.genereateCsvRows(data);
    return csv;
  }

  //these two functions are basically copied from Topic.js
  // These should be refactored to a generic paging aware message retriever
  getMessages() {
    var isAwsOrganisation = document
      .querySelector(".data")
      .getAttribute("data-organisation-isaws");
    if (isAwsOrganisation == "true") {
      App.Topic.isAwsOrg = true;
    } else {
      App.Topic.isAwsOrg = false;
    }

    var topic = document.querySelector(".data").getAttribute("data-topic");
    if (App.Topic.isAwsOrg) {
      var uri =
        App.Topic.awsApiDomain +
        "/topicMessages?topic=" +
        //"/default/AWSAggregate?topic=" +
        topic +
        "&startTime=" +
        encodeURIComponent(App.Topic.startDate) +
        "&endTime=" +
        encodeURIComponent(App.Topic.endDate) +
        "&fetchLastWritten=true&checkpoint="+this.checkpoint;
    } else {
      var uri =
        App.Topic.apiDomain +
        "/v1/messages/topic/" +
        topic +
        "?start-date=" +
        encodeURIComponent(App.Topic.startDate) +
        "&end-date=" +
        encodeURIComponent(App.Topic.endDate);
    }
    this.messagesForTopic(uri);
  }

  async messagesForTopic(uri) {
    App.showModal();
    var apiKey = document.querySelector(".data").getAttribute("data-api-key");

    let json = [];

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    axios.defaults.headers.common["Authorization"] = "api-key " + apiKey;

    axios
      .request({ url: uri, timeout: this.timeout })
      .then(response => {
        json["messages"] = response.data["Items"];

        App.Topic.fetchedLastWrittenData =
          response.data["fetchedLastWrittenData"];

        App.Topic.allMessages = App.Topic.allMessages.concat(json.messages);

        const lastMessage = json.messages[json.messages.length - 1];
        console.log(lastMessage);

        if (response.data["next"] != undefined && App.Topic.TESTING != true) {
          //console.log("Paging");
          this.messagesForTopic(response.data["next"]);
        } else {
          App.hideModal();

          const csvContent = this.generateCsv(App.Topic.allMessages);

          this.buildFilename(
            App.Topic.allMessages[0],
            App.Topic.allMessages[0].rec,
            App.Topic.allMessages[App.Topic.allMessages.length-1].rec
          );


          this.downloadCsv(
            csvContent,
            this.fileName,
            "text/csv;encoding:utf-8"
          );
        }
      })
      .catch(error => {
        if (error.code == "ECONNABORTED") {
          const logger = new ScsLogger();
          logger.log(`${user} has triggered an error`);
          logger.log("json2csv get connection timeout: " + error.message);
        }
        return true;
      });
  }

  downloadCsv(content, fileName, mimeType) {
    var a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      //html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      location.href =
        "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
    }
  }
}
export default Json2Csv;
