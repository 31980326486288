"use strict";

import MessageFormat from "./message_format";

class DataInterpretation {
  static reportedDataInterpretationName = 'reported';
  static highestPriorityModelName = 's1/2020h1';

  static whatDataInterpretationIsBeingDisplayed(sensor){
    let dataInterpretationName = '';
    if(App.Topic.showReported){
      dataInterpretationName = 'reported';
    } else {
      dataInterpretationName = DataInterpretation.getDataInterpretationName();
    }
    if(this.dataInterpretationPresentForSensor(sensor, dataInterpretationName)){
      return dataInterpretationName;
    } else {
      return DataInterpretation.reportedDataInterpretationName;
    }
  }

  static dataInterpretationPresentForSensor(sensor, dataInterpretation){
    if(Object.keys(App.Topic.processedMessages[sensor][dataInterpretation]['readings']).length == 0){
      return false;
    } else {
      return true;
    }
  }

  static getInterpretationsPresent() {
    App.Topic.dataInterpretations = [DataInterpretation.reportedDataInterpretationName];
    if (App.Topic.lastMessage["exg"] != undefined) {
      // if the format is v2.0 then get the name from exg.src
      // else do it the normal way
      App.Topic.dataInterpretations = App.Topic.dataInterpretations.concat(
        Object.keys(App.Topic.lastMessage["exg"])
      );
    }
    
    //now initialise messagesWithTimestamps which is used by gauges.
    for (var i = 0; i < App.Topic.valuesPresent.length; i++) {
      let value = App.Topic.valuesPresent[i];
      for (var ii = 0; ii < App.Topic.dataInterpretations.length; ii++) {
        var dataInterpretation = App.Topic.dataInterpretations[ii];
        App.Topic.messagesWithTimestamps[value][dataInterpretation] = [];
      }
    }

    // now construct models that should be used in the graphs.
    let models = [DataInterpretation.reportedDataInterpretationName];
    if(App.Topic.dataInterpretations.length == 1){
      return models;
    }
    models.concat(DataInterpretation.highestPriorityDataInterpretationName());
    return models;
    
  }

  static setInterpretationsPresent() {
    let modelsPresent = ['reported'];
    if (App.Topic.lastMessage != undefined && App.Topic.lastMessage["exg"] != undefined) {
      if(MessageFormat.version(App.Topic.lastMessage) < 2.0){
        modelsPresent = modelsPresent.concat(
          Object.keys(App.Topic.lastMessage["exg"])
        );
      } else {
        modelsPresent = modelsPresent.concat(
          App.Topic.lastMessage.exg.src
        );
      } 
    }

    let modelsToDisplay = [DataInterpretation.reportedDataInterpretationName];
    if(modelsPresent.length > 1){
      modelsToDisplay = modelsToDisplay.concat(DataInterpretation.highestPriorityDataInterpretationName(modelsPresent));
    }

    //now initialise messagesWithTimestamps which is used by gauges.
    for (var i = 0; i < App.Topic.valuesPresent.length; i++) {
      let value = App.Topic.valuesPresent[i];
      for (var ii = 0; ii < modelsToDisplay.length; ii++) {
        let dataInterpretation = modelsToDisplay[ii];
        App.Topic.messagesWithTimestamps[value][dataInterpretation] = [];
      }
    }
    
    App.Topic.dataInterpretations = modelsToDisplay;
  }

  static highestPriorityDataInterpretationName(models){
    if(models.length == 1){
      return models[0];
    } else if(models.includes(DataInterpretation.highestPriorityModelName)){
      return DataInterpretation.highestPriorityModelName;
    } else {
      return models[models.length - 1]
    }
  }

  static getDataInterpretationName(){
    if(App.Topic.dataInterpretations.length > 1){
      return App.Topic.dataInterpretations[App.Topic.dataInterpretations.length - 1];
    } else {
      return 'reported'
    }
  }

  static showDataInterpretationName(){ 
    let dataInterpretationName = DataInterpretation.getDataInterpretationName();
    if(dataInterpretationName != undefined){
      document.getElementById("data_interpretation_name").textContent = 'Interpretation: '+dataInterpretationName;  
    }
  }
}
export default DataInterpretation;
