class ChartToggler {
  constructor() {}

  static toggle(toggler) {
    const gaugesDiv = document.getElementById("gauges_div");
    const chartsDiv = document.getElementById("charts_div");
    const gauges = document.querySelectorAll(".gauge");

    if (toggler.dataset.charttype == "gauge") {
      const isPressed = toggler.getAttribute("aria-pressed");
      const graphs = document.querySelectorAll(".graph");

      //if this is true then the button has been click off, and this will be false shortly.
      if (isPressed == "true") {
        Array.prototype.forEach.call(gauges, function(gauge) {
          gauge.style.display = "none";
        });
        Array.prototype.forEach.call(graphs, function(graph) {
          graph.setAttribute("class", "graph-wide");
        });

        document.cookie = "showGauges=false;path=/;max-age=0"; //epxires 0 should delete the cookie.
      } else {
        // console.log("Showing gauges");
        const wideGraphs = document.querySelectorAll(".graph-wide");
        // console.log(`i see ${wideGraphs.length} wide graphs`);
        // console.log(wideGraphs);
        [].forEach.call(wideGraphs, function(wideGraph) {
          //console.log("Narrowing a graph");
          wideGraph.setAttribute("class", "graph");
        });

        Array.prototype.forEach.call(gauges, function(gauge) {
          gauge.style.display = "inline-block";
        });

        //const wideGraphs2 = document.querySelectorAll("graph-wide");
        //console.log(`now i see ${wideGraphs2.length} wide graphs`);
        let expiryDate = new Date(Date.now() + 3600 * 1000 * 24 * 365 * 1); //1 year
        expiryDate = expiryDate.toUTCString();
        document.cookie = "showGauges=true;path=/;expires=" + expiryDate;
      }
    }

    App.Topic.graphs.forEach(function(g) {
      g.draw();
    });
  }
}
export default ChartToggler;
