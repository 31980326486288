class ScsLogger {
   constructor() {
    this.uuid = ScsLogger.uuidv4();
    this.counter = 0; //this allows multiple messages per error report
  }

  log(message) {
    this.counter = this.counter + 1;
    const url =
      "https://vmxrvbzhoi.execute-api.us-west-2.amazonaws.com/dev/log";

    axios
      .post(url, {
        type: "error",
        service: "CH",
        uuidv4: this.uuid,
        counter: this.counter,
        message: message,
        user: user,
        loggedAt: new Date().toISOString()
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
        return true;
      });
  }

  //https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  static uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
}
export default ScsLogger;
