/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import CloudHelper from "./cloud_helper";
window.CloudHelper = CloudHelper;

import Topic from "./topic";
window.Topic = Topic;

import TopicAutoRefresher from "./topic_auto_refresher";
window.TopicAutoRefresher = TopicAutoRefresher;

import TopicMessagesRetriever from "./topic_messages_retriever";
window.TopicMessagesRetriever = TopicMessagesRetriever;

import RegressionCalculator from "./regression_calculator";
window.RegressionCalculator = RegressionCalculator;

// import Countdown from "./countdown";
// window.Countdown = Countdown;

import TopicMap from "./topic_map";
window.TopicMap = TopicMap;

import ChartToggler from "./chart_toggler";
window.ChartToggler = ChartToggler;

import TopicTimeTool from "./topic_time_tool";
window.TopicTimeTool = TopicTimeTool;

import Json2Csv from "./json2csv";
window.Json2Csv = Json2Csv;

import ScsLogger from "./scs_logger";
window.ScsLogger = ScsLogger;

import ScsConfig from "./scs_config";
window.ScsConfig = ScsConfig;

import Message from "./message";
window.Message = Message;

import MessageValue from "./message_value";
window.MessageValue = MessageValue;

import MessageCollections from "./message_collections";
window.MessageCollections = MessageCollections;

import MessageFormat from "./message_format";
window.MessageFormat = MessageFormat;

import MessageProcessor from "./message_processor";
window.MessageProcessor = MessageProcessor;

import DataInterpretation from "./data_interpretation";
window.DataInterpretation = DataInterpretation;

import Graph from "./graph";
window.Graph = Graph;

import TimeHelper from "./time_helper";
window.TimeHelper = TimeHelper;

// Adding this here as it is consumed in App.Topic.js which can't do imports
import valueLabels from './config/valueLabels';
window.valueLabels = valueLabels;

document.addEventListener("DOMContentLoaded", function(event) {
  let params = new URLSearchParams(document.location.search.substring(1));
  let showPerformance = params.get("perf");
  if(showPerformance){
    let perfDiv = document.getElementById('performance-stats');
    perfDiv.style.display = 'block'
  }
})
