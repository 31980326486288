class Topic {
  constructor() {}

  // static hello() {
  //   return "Hello from the Topic class";
  // }

  static minMax(value){
    const minMaxValues = ['pm1', 'pm2p5', 'pm10'];
    return minMaxValues.includes(value);
  }

  static getMaxYValue(value, dataInterpretation) {
    dataInterpretation = DataInterpretation.whatDataInterpretationIsBeingDisplayed(value);

    if (value == "sht") {
      return App.Topic.nearestUp("hmd", 10, dataInterpretation);
    } else if (value == "psu") {
      return App.Topic.nearestUp("prot-batt", 10, dataInterpretation);
    } else if (valueLabels[value]['graphMaxY'] != undefined) {
      return valueLabels[value]['graphMaxY']
    } else {
      const maxValue = Graph.maxYValue(value, dataInterpretation);
      if(maxValue < 10 && Topic.minMax(value)){
        return 10
      } else {
        return maxValue;
      }
    }
  }
  static getMinYValue(sensorName, dataInterpretation) {
    dataInterpretation = DataInterpretation.whatDataInterpretationIsBeingDisplayed(sensorName);
    let values = Object.values(
      App.Topic.processedMessages[sensorName][dataInterpretation].readings
    );
    const minValue = MessageCollections.getMinValueForSensor(
      sensorName,
      dataInterpretation
    );
    if(valueLabels[sensorName]['graphMinY'] != undefined){
      if(typeof valueLabels[sensorName]['graphMinY'] == 'string'){
        if(valueLabels[sensorName]['graphMinY'] == 'round'){
          return minValue - 1;
        }
        if(valueLabels[sensorName]['graphMinY'].startsWith('lte')){
          const graphMinY = parseInt(valueLabels[sensorName]['graphMinY'].split('lte')[1]);
          if(minValue < graphMinY){
            return minValue;
          } else {
            return graphMinY;
          }
        }
      }
      if(typeof valueLabels[sensorName]['graphMinY'] == 'number'){
        return valueLabels[sensorName]['graphMinY'];
      }
    }
  }

  static showReportedToggler(){
    if(App.Topic.dataInterpretations.length > 1){
      document.getElementById('showReportedToggler').style.display = "block";
    } else {
      document.getElementById('showReportedToggler').style.display = "none";
    }
  }

  static setTopicInterval(interval){
    if(interval != undefined){
      App.Topic.interval = interval;
    }
  }

}
export default Topic;
