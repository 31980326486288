"use strict";

class MessageCollections {
  static messagesWithTimestamps(sensorName, message, dataInterpretation) {
    // const timeStamp = new Date(
    //   moment(message.rec, App.Topic.messageTimeFormatString)
    // );
    const timeStamp = new Date(message.rec);
    this.initialiseSensorCollection(sensorName, dataInterpretation);

    try {
      if (sensorName == "pt1") {
        App.Topic.messagesWithTimestamps[sensorName][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val["pt1"]["tmp"],
        ]);
      } else if (sensorName == "sht") {
        App.Topic.messagesWithTimestamps["hmd"][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val["sht"]["tmp"],
        ]);
        App.Topic.messagesWithTimestamps["tmp"][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val["sht"]["tmp"],
        ]);
      } else if (sensorName == "psu") {
        App.Topic.messagesWithTimestamps["pwr-in"][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val["psu"]["pwr-in"],
        ]);
        App.Topic.messagesWithTimestamps["prot-batt"][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val["psu"]["prot-batt"],
        ]);
      } else if (sensorName == "tmp" && App.Topic.isAStatusTopic()) {
        if (message.val["tmp"] != undefined) {
          if (message.val["tmp"]["brd"] != undefined) {
            if (
              App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] ==
              undefined
            ) {
              App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] = [];
            }
            App.Topic.messagesWithTimestamps["tmp"][dataInterpretation].push([
              new Date(timeStamp).getTime(),
              +message.val["tmp"]["brd"],
            ]);
          } else if (message.val["tmp"]["hst"] != undefined) {
            if (
              App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] ==
              undefined
            ) {
              App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] = [];
            }
            App.Topic.messagesWithTimestamps["tmp"][dataInterpretation].push([
              new Date(timeStamp).getTime(),
              +message.val["tmp"]["hst"],
            ]);
          }
        }
      } else if (sensorName == "up") {
        if (
          message.val["up"] != undefined &&
          message.val["up"]["load"] != undefined
        ) {
          if (
            App.Topic.messagesWithTimestamps["up"][dataInterpretation] ==
            undefined
          ) {
            App.Topic.messagesWithTimestamps["up"][dataInterpretation] = [];
          }
          App.Topic.messagesWithTimestamps["up"][dataInterpretation].push([
            new Date(timeStamp).getTime(),
            +message.val["up"]["load"]["av5"],
          ]);
        }
      } else if (App.Topic.isSimpleValue(sensorName)) {
        App.Topic.messagesWithTimestamps[sensorName][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val[sensorName],
        ]);
      } else {
        App.Topic.messagesWithTimestamps[sensorName][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          +message.val[sensorName]["cnc"],
        ]);
      }  
    } catch(error){
      console.log(`I got an error getting the message with timestamp. sensorName: ${sensorName}.`);
      console.log('The message is:');
      console.log(message);
      console.log(`The error is ${error}`);
      App.Topic.messagesWithTimestamps[sensorName][dataInterpretation].push([
          new Date(timeStamp).getTime(),
          null]);
    }
    
  }

  static getMaxValueForSensor(sensorName, dataInterpretation, messages) {
    var values = [];
    try {
      if (sensorName == "prot-batt") {//Return highest max as these are plotted on the same axis
        let pwrInReadings = Object.entries(App.Topic.processedMessages["psu"]['reported'].readings).map(r => r[1][0]);
        let pwrInMax = Math.max.apply(null, pwrInReadings);
        let prottBattReadings = Object.entries(App.Topic.processedMessages["psu"]['reported'].readings).map(r => r[1][1]);
        let prottBattMax = Math.max.apply(null, prottBattReadings);
        
        return Math.max.apply(null, [pwrInMax, prottBattMax])+10;
      } else if (sensorName == "hmd" && App.Topic.isASHT()) {
        let hdmReadings = Object.entries(App.Topic.processedMessages["sht"]['reported'].readings).map(r => r[1][0]);
        return Math.max.apply(null, hdmReadings);
      } else if (sensorName == "tmp" && App.Topic.isASHT()) {
        let tmpReadings = Object.entries(App.Topic.processedMessages["sht"]['reported'].readings).map(r => r[1][1]);
        return Math.max.apply(null, tmpReadings);
      } else {
        // get the max of the DI passed in.
        //if(App.Topic.processedMessages[sensorName][dataInterpretation] != undefined && Object.keys(App.Topic.processedMessages[sensorName][dataInterpretation]['readings']).length != 0){
        //  return Math.max(...Object.values(App.Topic.processedMessages[sensorName][dataInterpretation]['readings']))
        //} else {
        //  return Math.max(...Object.values(App.Topic.processedMessages[sensorName]['reported']['readings']))  
        //}
        
        //if there is more than one data interpretation for the sensor then I should get the max of both data interpretations
        //how many data interpretations are there
        const dataInterpretations = App.Topic.dataInterpretations;
        if(dataInterpretations.length > 1){
          // get the max of the max of all data interpretations
         let maxes = [];
          for(let i=0;i < dataInterpretations.length;i++){
            maxes.push(Object.values(App.Topic.processedMessages[sensorName][dataInterpretations[i]].readings))
          }
          return Math.max(...maxes);
        } else {
          return Math.max(...Object.values(App.Topic.processedMessages[sensorName]['reported']['readings']));
        }
      }
    } catch (error) {
      console.log("I got an error getting max value for: " + sensorName + `${error}`);
    }
    return Math.max.apply(null, values);
  }

  static getMinValueForSensor(sensorName, dataInterpretation, subPart=undefined) {
    var values = [];
    try {
      if (sensorName == "prot-batt") {
        // let pwrInReadings = Object.entries(App.Topic.processedMessages["psu"]['default'].readings).map(r => r[1][0]);
        // let pwrInMax = Math.max.apply(null, pwrInReadings);
        // let prottBattReadings = Object.entries(App.Topic.processedMessages["psu"]['default'].readings).map(r => r[1][1]);
        // let prottBattMax = Math.max.apply(null, prottBattReadings);
        
        // return Math.max.apply(null, [pwrInMax, prottBattMax])+10;
       
      } else if (sensorName == "hmd" && App.Topic.isASHT()) {
        // let hdmReadings = Object.entries(App.Topic.processedMessages["sht"]['default'].readings).map(r => r[1][0]);
        // return Math.max.apply(null, hdmReadings);
       
      } else if (sensorName == "tmp" && App.Topic.isASHT()) {
        // let tmpReadings = Object.entries(App.Topic.processedMessages["sht"]['default'].readings).map(r => r[1][1]);
        // return Math.max.apply(null, tmpReadings);
      } else if(sensorName == 'sht'){
        if(subPart == 'tmp'){
          let tmpReadings = Object.entries(App.Topic.processedMessages["sht"]['reported'].readings).map(r => r[1][1]);
          let min = Math.min.apply(null, tmpReadings);
          if(min > 0){
            return 0
          } else {
            return min
          }
        }
      } else {
        const dataInterpretations = App.Topic.dataInterpretations;

        //if(dataInterpretations.length > 1){
          // get the min of all data interpretations
         let mins = new Array();
          //for(let i=0;i < dataInterpretations.length;i++){
            mins.push(Object.values(App.Topic.processedMessages[sensorName][DataInterpretation.whatDataInterpretationIsBeingDisplayed(sensorName)].readings))
          //}
          return Math.min(...mins.flat());
        // } else {
        //   return Math.min(...Object.values(App.Topic.processedMessages[sensorName]['reported']['readings']));
        // }
      }
    } catch (error) {
      console.log("I got an error getting min value for: " + sensorName + `${error}`);
    }
  }

  static initialiseSensorCollection(sensorName, dataInterpretation) {
    if (sensorName == "sht") {
      if (App.Topic.messagesWithTimestamps["hmd"] === undefined) {
        App.Topic.messagesWithTimestamps["hmd"] = {};
        App.Topic.messagesWithTimestamps["hmd"][dataInterpretation] = [];
      } else if (
        App.Topic.messagesWithTimestamps["hmd"][dataInterpretation] == undefined
      ) {
        App.Topic.messagesWithTimestamps["hmd"][dataInterpretation] = [];
      }
      if (App.Topic.messagesWithTimestamps["tmp"] === undefined) {
        App.Topic.messagesWithTimestamps["tmp"] = {};
        App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] = [];
      } else if (
        App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] == undefined
      ) {
        App.Topic.messagesWithTimestamps["tmp"][dataInterpretation] = [];
      }
    } else if (sensorName == "psu") {
      if (App.Topic.messagesWithTimestamps["pwr-in"] == undefined) {
        App.Topic.messagesWithTimestamps["pwr-in"] = {};
        App.Topic.messagesWithTimestamps["pwr-in"][dataInterpretation] = [];
      }
      if (App.Topic.messagesWithTimestamps["prot-batt"] == undefined) {
        App.Topic.messagesWithTimestamps["prot-batt"] = {};
        App.Topic.messagesWithTimestamps["prot-batt"][dataInterpretation] = [];
      }
    }
  }
}

export default MessageCollections;
