class TopicMap {
  constructor(long, lat, mapContainer) {
    this.long = long;
    this.lat = lat;
    this.mapContainer = mapContainer;
  }

  drawMap() {
    const coords = { lat: this.lat, lng: this.long };

    const map = new google.maps.Map(
      document.getElementById(this.mapContainer),
      { zoom: 13, center: coords, mapTypeId: "satellite" }
    );
    const marker = new google.maps.Marker({ position: coords, map: map });
    const container = document.getElementById(this.mapContainer);

    container.style.height = "500px";
    container.style.display = "block";
  }

  static noGPSData(mapContainer) {
    const container = document.getElementById(mapContainer);

    container.className += " noGpsContainer";
    container.style.display = "block";

    const heading = document.getElementById("noGps");
    heading.style.display = "block";
  }

  static gpsQualityDisplay(deviceMessage){
    if(TopicMap.hasValidGpsData(deviceMessage)){
      if (deviceMessage["val"]["gps"] != undefined) {
        if(deviceMessage["val"]["gps"]["qual"] != undefined){
          if(deviceMessage["val"]["gps"]["qual"] === 0){
            const container = document.getElementById("gps-warning");
            container.style.display = "block";
          }
        }
      }  
    }
  }

  static hasValidGpsData(deviceMessage){
    if(
      deviceMessage["val"]["gps"] != undefined &&
      deviceMessage["val"]["gps"]["pos"] != undefined &&
      deviceMessage["val"]["gps"]["pos"][0] != null &&
      deviceMessage["val"]["gps"]["pos"][1] != null
      ){
      return true;
    }
    return false;
  }
}
export default TopicMap;
