"use strict";

import DataInterpretation from "./data_interpretation";

class Graph {
  constructor() {}

  static displayGraphs() {
    App.Topic.time1 = 0;
    App.Topic.time1 = performance.now();
    var chartsDiv = document.getElementById("charts_div");
    //Clear out old graphs
    if (chartsDiv != undefined) {
      while (chartsDiv.hasChildNodes()) {
        chartsDiv.removeChild(chartsDiv.lastChild);
      }

      App.Topic.valuesPresent.forEach(function (sensor) {
        Graph.displayValueGraph(sensor, DataInterpretation.whatDataInterpretationIsBeingDisplayed(sensor));
      });

      //
      //Other stuff from here, not core graph display so should be moved.
      //

      if (App.Topic.isAStatusTopic()) {
        App.Topic.displayUptimeNumber();
        App.Topic.displayMap();
        var lastMessage =
          App.Topic.allMessages[App.Topic.allMessages.length - 1];
        //var messagePayload = lastMessage.payload;
        TopicMap.gpsQualityDisplay(lastMessage);
      }
      App.Topic.time2 = 0;
      App.Topic.time2 = performance.now();
      document.getElementsByClassName('renderTime')[0].textContent = `Time to render graphs is ${((App.Topic.time2 - App.Topic.time1)/1000).toFixed(2)} seconds`;
      App.hideModal();

      if (App.Topic.fetchedLastWrittenData) {
        App.Topic.lastWrittenDataDetected();
      } else {
        document.getElementById("older_data_message").style.display = "none";
        document.getElementById("refresh-button-container").style.display =
          "block";
      }
      if (App.Topic.allMessages.length == 0) {
        alert("No data has been found for the specified time period.");
        return false;
      }
      App.Topic.constructAndDisplayShareableUrl();

      //display gauge graphs
      //this.displayGauges();

      App.Topic.constructAndDisplayCSVlink();
    }
  }

  /** Display a graph for a given sensor 
   * @param {string} sensor - The name of the sensor to display a graph for
  */
  static displayValueGraph(sensor, dataInterpretation = "reported") {
    //Prepare the html
    var chart_div = Graph.getChartContainer(sensor);
    let graph_div = Graph.getGraphDiv(sensor);

    chart_div.appendChild(graph_div);

    var charts_div = document.getElementById("charts_div");
    charts_div.appendChild(chart_div);
    let sensorData = App.Topic.processedMessages[sensor];
    
    var data = new google.visualization.DataTable();
    Graph.setGraphColumns(data, sensor, sensorData); //If I do not have data for the data model then i shouldn't create a column for it.

    var chart = new google.visualization.ComboChart(graph_div);
    let chartDataSet = []; //array of data that the chart renders
    
    for (let i = 0; i < sensorData["reported"].timeStamps.length; i++) {
      let timestamp = sensorData["reported"].timeStamps[i];
      chartDataSet.push(Graph.constructGraphDatasetRow(sensorData, timestamp, sensor, dataInterpretation));
    }
    
    try {
      data.addRows(chartDataSet);
    } catch (e) {
      console.log("caught an error" + e);
      console.log("Value is " + sensor);
      console.log(chartDataSet[0]);
    }
    let options = Graph.graphOptions(sensor, data, dataInterpretation);

    var dateFormatter = new google.visualization.DateFormat({
      pattern: "yyyy-MM-dd HH:mm:ss",
    });
    dateFormatter.format(data, 0);
    var chartWrapper = new google.visualization.ChartWrapper({
      chartType: "ComboChart",
      dataTable: data,
      options: options,
      containerId: "graph" + sensor,
    });
    chartWrapper.draw();
    //chart.draw(data, options);
    App.Topic.graphs.push(chartWrapper);
    //Topic.showDataInterpretationName();
    DataInterpretation.showDataInterpretationName();
  }

  static getChartContainer(sensorName) {
    if (document.getElementById("chartContainer" + sensorName) == undefined) {
      var chart_div = document.createElement("div");
      chart_div.setAttribute("class", "chart chart" + sensorName);
      chart_div.setAttribute("id", "chartContainer" + sensorName);
    } else {
      var chart_div = document.getElementById("chartContainer" + sensorName);
    }
    return chart_div;
  }

  static getGraphDiv(sensorName) {
    if (document.getElementById("graph" + sensorName) == undefined) {
      var graph_div = document.createElement("div");
      graph_div.setAttribute("id", "graph" + sensorName);
    } else {
      document.getElementById("graph" + sensorName).remove();
      var graph_div = document.createElement("div");
      graph_div.setAttribute("id", "graph" + sensorName);
    }

    if (
      (document.querySelectorAll("#charts_div .gauge").length > 0 &&
        document.querySelectorAll("#charts_div .gauge")[0].style.display ==
          "") ||
      App.Topic.displayGaugesFlag
    ) {
      graph_div.setAttribute("class", "graph graph" + sensorName);
    } else {
      graph_div.setAttribute("class", "graph-wide xgraph" + sensorName);
    }

    return graph_div;
  }

  static setGraphColumns(data, value, sensorData) {
    data.addColumn("datetime", "X");
    
    if (value == "sht") {
      data.addColumn("number", valueLabels["hmd"]["label"]);
      data.addColumn("number", valueLabels["tmp"]["label"]);
    } else if (value == "psu") {
      data.addColumn("number", "Input");
      data.addColumn("number", "Battery");
    } else if (value == "tmp" && App.Topic.isAStatusTopic()) {
      data.addColumn("number", "avg");
      data.addColumn("number", valueLabels["internalTmp"]["label"]);
    } else {
      if (valueLabels[value] != undefined) {
        data.addColumn("number", "avg");
        let dataInterpretations = Graph.getDataInterpretationNames();
        if(this.missingModelData(sensorData) && dataInterpretations.length > 1){
          dataInterpretations.pop();
        }
        for (let i = 0; i < dataInterpretations.length; i++) {
          if(value == 'up'){
            data.addColumn("number", 'cpu');
          } else if(value == 'sig'){
            data.addColumn("number", 'quality');
          } else if(value == 'sfr'){
            data.addColumn("number", 'sfr');
          } else if(i > 0){//we are showing both
            data.addColumn("number", 'interpreted');
          } else{
            if(App.Topic.showReported === true || Graph.getDataInterpretationNames()[0] == ['reported'] || this.missingModelData(sensorData)){
              data.addColumn("number", 'reported');
            } else {
              data.addColumn("number", 'interpreted');
            }
          }
        }
      } else {
        data.addColumn("number", value);
      }
    }
  }

  static missingModelData(sensorData){
    //let modelName = DataInterpretation.highestPriorityDataInterpretationName()
    if(App.Topic.dataInterpretations.length == 1){
      return false
    }
    let modelName = App.Topic.dataInterpretations[App.Topic.dataInterpretations.length - 1];
    if(sensorData[modelName]['timeStamps'].length == 0){
      return true;
    } else {
      return false;
    }
  }

  static lineOpacity(dataInterpretations, dataInterpretation) {
    if(dataInterpretation == 'reported'){
      //return 'opacity: 0.5;'
      return 'opacity: 1.0;color: #cccccc;'
    } else {
      return 'opacity: 1.0;'
    }
    // if(dataInterpretations.length > 1 && dataInterpretation != 'reported'){
    //   return 'opacity: 1.0;'
    // } else if(dataInterpretations.length == 1 && dataInterpretation != 'reported'){
    //   return 'opacity: 1.0;'
    // } else {
    //   return 'opacity: 0.5;'
    // }
  }

  static graphOptions(value, data, dataInterpretation) {
    if (value == "sht") {
      var options = {
        title: value,
        seriesType: "line",
        series: {
          0: {
            targetAxisIndex: 0,
            color: valueLabels["hmd"]["colour"],
          },
          1: {
            targetAxisIndex: 1,
            color: valueLabels["tmp"]["colour"],
          },
        },
        vAxes: {
          0: {
            title: "%",
            viewWindowMode: "explicit",
            viewWindow: {
              max: App.Topic.nearestUp("hmd", 10, dataInterpretation),
              min: 0,
            },
          },
          1: {
            title: "Temp (Celsius)",
            viewWindow: {
              max: App.Topic.nearestUp("tmp", 10, dataInterpretation),
              min: MessageCollections.getMinValueForSensor(value, dataInterpretation, 'tmp'),
            },
          },
        },
        hAxis: {
          format: "yyyy-MM-dd HH:mm",
        },
        title: App.Labels.longLabelForValue(value),
      };
    } else if (value == "psu") {
      var options = {
        title: "Device power",
        seriesType: "line",
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 0 },
        },
        vAxes: {
          0: {
            title: "V",
            viewWindowMode: "explicit",
            viewWindow: {
              max: App.Topic.nearestUp("prot-batt", 10),
              //max: Topic.getMaxYValue(value, dataInterpretation),
              min: 0,
            },
          },
        },
        hAxis: {
          format: "yyyy-MM-dd HH:mm",
        },
      };
    } else {
      var options = {
        series: {
          0: {
            color: "#CCC",
            lineWidth: 2.0,
            visibleInLegend: false
          },
          1: {
            //color: valueLabels[value]["colour"],
            color: Graph.calculateLineColour(value, 1),
            //color: "#FFF",
            lineWidth: 2.0
          },
          2: {
            //color: valueLabels[value]["colour"],
            color: Graph.calculateLineColour(value, 2),
            //color: "#CCC",
            lineWidth: 2.0
          },
        },
        hAxis: {
          format: "yyyy-MM-dd HH:mm",
        },
        vAxes: {
          0: {
            title: valueLabels[value]["unit"],
            viewWindowMode: "explicit",
            viewWindow: {
              max: Topic.getMaxYValue(value, dataInterpretation),
              min: Topic.getMinYValue(value, dataInterpretation),
            },
          },
        },
        title: Graph.graphTitle(value)
      };
    }
    return options;
  }

  static calculateLineColour(sensorName, lineNumber){
    const defaultColourForSensor = valueLabels[sensorName]["colour"];
    // If the data interpretation model for the sensor is reported then always show it as grey
    // if(DataInterpretation.whatDataInterpretationIsBeingDisplayed(sensorName) == 'reported'){
    //   return "#CCCCCC"
    // }
    if(App.Topic.showReported === true && lineNumber === 1){
      return "#CCCCCC"
    } else {
      return defaultColourForSensor;
    }
  }
  
  static graphTitle(sensorName){
    return App.Labels.longLabelForValue(sensorName);    
  }

  static getAverageSensorValue(sensorData) {
    let dataInterpretations = Graph.getDataInterpretationNames();
    
    let lastDataInterpretationName =
      dataInterpretations[dataInterpretations.length - 1];
    return sensorData[lastDataInterpretationName].average;
  }

  // In the future when there are more than 2 DIs this will work out which ones are actually being plotted
  // Use this as a proxy to a better method.
  static getDataInterpretationNames() {
    if(App.Topic.showReported === true || App.Topic.dataInterpretations.length == 1){
      return App.Topic.dataInterpretations.slice(0, App.Topic.dataInterpretations.length);//return a copy, not a reference
    } else if(App.Topic.dataInterpretations.includes('s1/2020h1')){
      return ['s1/2020h1'];
    } else {
      return App.Topic.dataInterpretations.slice(1, App.Topic.dataInterpretations.length)
    }
  }

  static constructGraphDatasetRow(sensorData, timestamp, sensorName, dataInterpretation) {
    let dataInterpretations = Graph.getDataInterpretationNames();

    if(this.missingModelData(sensorData) && !App.Topic.showReported === true){
      dataInterpretations.unshift('reported');
    }
    let values = [];
    if(sensorName == 'sht' || sensorName == 'psu'){
      let rowData = sensorData['reported'].readings[timestamp];
      if(rowData == undefined){
        values = values.concat([null, null]);
      } else {
        values = values.concat([rowData[0], rowData[1]]);
      }
    } else {    
      for (let i = 0; i < dataInterpretations.length; i++) {
        if(sensorData[dataInterpretations[i]].timeStamps.length != 0){
          let rowData = sensorData[dataInterpretations[i]].readings[timestamp];
          values = values.concat([rowData]);
        }
      }
    }
        
    if(sensorName == 'sht'){
      return [
        new Date(timestamp),
      ].concat(values);
    } else if(sensorName == 'psu'){
        return [
          new Date(timestamp),
        ].concat(values);
    } else {
      if(this.missingModelData(sensorData)){
        return [
          new Date(timestamp),
          App.Topic.processedMessages[sensorName]['reported']['averages'][timestamp],
        ].concat(values);
      } else {
        return [
          new Date(timestamp),
          App.Topic.processedMessages[sensorName][DataInterpretation.getDataInterpretationName()]['averages'][timestamp],
        ].concat(values);
      }
      
    }
    
  }

  static maxYValue(sensorName, dataInterpretation) {
    if (sensorName == "hmd") {
      var maxValue =
        MessageCollections.getMaxValueForSensor("hmd", dataInterpretation);
      return maxValue + (10 - (maxValue % 10));
    } else if (sensorName == "tmp") {
      var maxValue =
        MessageCollections.getMaxValueForSensor("tmp", dataInterpretation);
      return maxValue + (10 - (maxValue % 10));
    } else if (sensorName == "pwr-in") {
      return 20;
    } else if (sensorName == "prot-batt") {
      return 10;
    } else if (sensorName == "up") {
      var maxValue = MessageCollections.getMaxValueForSensor(
        sensorName,
        dataInterpretation
      );
      return maxValue + 1;
    } else if (sensorName == "tmp" && App.Topic.isAStatusTopic()) {
      return App.Topic.nearestUp("tmp", 10, "reported");
    } else if (valueLabels[sensorName]['maxY'] == 'round'){
      var maxValue = MessageCollections.getMaxValueForSensor(
        sensorName,
        dataInterpretation
      );
      return Math.round(maxValue) + 1;
    } else {
      var maxValue = MessageCollections.getMaxValueForSensor(
        sensorName,
        dataInterpretation
      );
      //below pads the max value where it is small
      if (maxValue < 1) {
        return maxValue;
      }
      if (maxValue / 6 > 100) {
        return Math.ceil(maxValue / 100) * 100;
      } else {
        return Math.ceil(maxValue / 10) * 10;
      }
    }
  }

  static displayGauges() {
    App.Topic.valuesPresent.forEach(function (value) {
      if (value != "psu" && value != "sht") {
        var regressionCalculator = new RegressionCalculator();
        //console.log(`Midpoint is ${regressionCalculator.getMidPoint(value)}`);
        var yValue = regressionCalculator.predictY(
          value,
          regressionCalculator.getMidPoint(value)
        );
      }
      //console.log(`yValue is ${yValue}`);
      if (value != "psu" && value != "sht") {
        var data = google.visualization.arrayToDataTable([
          ["Label", "Value"],
          [valueLabels[value]["unit"], yValue],
        ]);
      }

      var options = {
        width: 400,
        height: 120,
        // redFrom: 90,
        // redTo: 100,
        // yellowFrom: 75,
        // yellowTo: 90,
        max: MessageCollections.getMaxValueForSensor(value, DataInterpretation.getDataInterpretationName()),
        minorTicks: 5,
      };

      var gauge_div = document.createElement("div");

      var gauges_div = document.getElementById("gauges_div");
      gauges_div.appendChild(gauge_div);

      if (value != "psu" && value != "sht") {
        var gauge = new google.visualization.Gauge(gauge_div);
        gauge.draw(data, options);

        var gaugeValue = document.createElement("div"); //This is not going to be the Unit but rather the 'value', eg pm10
        gaugeValue.setAttribute("class", "gauge-value");
        gaugeValue.innerHTML = value; //valueLabels[value]["unit"];
      }
      var imageContainer = document.createElement("div");
      imageContainer.setAttribute("class", "imageContainer");

      var gaugeContainer = document.createElement("div");
      gaugeContainer.setAttribute("class", "gaugeContainer");
      gaugeContainer.appendChild(gauge_div);
      if (value != "psu" && value != "sht") {
        gaugeContainer.appendChild(gaugeValue);
      }
      var outerContainer = document.createElement("div");
      outerContainer.setAttribute(
        "class",
        "col-md-" +
          24 / Object.keys(App.Topic.processedMessages).length +
          " centred-content gauge"
      );
      outerContainer.appendChild(gaugeContainer);

      gauges_div.appendChild(outerContainer);
      const graphContainer = document.getElementsByClassName(
        "chart" + value
      )[0];

      //const inlineGaugeContainer = outerContainer.cloneNode(true);
      if (document.getElementById("gaugeContainer" + value)) {
        document.getElementById("gaugeContainer" + value).remove();
      }
      inlineGaugeContainer.setAttribute("class", "gauge gauge" + value);
      if (App.Topic.displayGaugesFlag == true) {
        inlineGaugeContainer.style.display = "inline-block";
      }

      graphContainer.appendChild(inlineGaugeContainer);
    });
  }

  static nearestUp(value, roundUpTo, dataInterpretation) {
    var maxValue =
      MessageCollections.getMaxValueForSensor(value, dataInterpretation);
    return maxValue + (roundUpTo - (maxValue % roundUpTo));
  }
}
export default Graph;
