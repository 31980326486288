"use strict";

import MessageFormat from "./message_format";

class MessageValue {
  static valueIsNull(message, sensorName) {
    if (sensorName == "pt1") {
      return message.val["pt1"]["tmp"] == null;
    } else if (sensorName == "sht") {
      return (
        message.val["sht"]["hmd"] == null || message.val["sht"]["tmp"] == null
      );
    } else if (sensorName == "psu") {
      return (
        message.val["psu"]["pwr-in"] == null ||
        message.val["psu"]["prot-batt"] == null
      );
    } else if (sensorName == "tmp" && App.Topic.isAStatusTopic()) {
      if (message.val["tmp"] != undefined) {
        if (message.val["tmp"]["brd"] != undefined) {
          return message.val["tmp"]["brd"] == null;
        } else if (message.val["tmp"]["hst"] != undefined) {
          return message.val["tmp"]["hst"] == null;
        }
      }
    } else if (sensorName == "up") {
      if (
        message.val["up"] != undefined &&
        message.val["up"]["load"] != undefined
      ) {
        return message.val["up"]["load"]["av5"] == null;
      }
    } else if (App.Topic.isSimplesensorName(sensorName)) {
      return message.val[sensorName] == null;
    } else {
      return message.val[sensorName]["cnc"] == null;
    }
  }

  static randomlyNullify(sensorValue) {
    if (Math.floor(Math.random() * 50) < 2) {
      return null;
    } else {
      return sensorValue;
    }
  }
  //Given a message extract the sensor sensorValue from the message
  // This function needs a refactor as the addition of dataPath to the valueLabels
  // means at least some of this function is redundant
  static valueFor(sensorName, message, dataInterpretation) {
    if (dataInterpretation === "reported") {
      var dataPath = "val";
    } else {
      if(MessageFormat.version(message) < 2.0){
        var dataPath = "exg." + dataInterpretation;
      } else {
        var dataPath = "exg.val";
      }
    }

    let sensorValue = null;
    try {
      if (sensorName == "pt1") {
        dataPath = dataPath + ".pt1.tmp";
        sensorValue = MessageValue.getValue(message, dataPath);
      } else if (sensorName == "sig") {
        sensorValue = MessageValue.getValue(message, dataPath + ".sig.quality");
      } else if (sensorName == "sht") {
        sensorValue = [
          MessageValue.getValue(message, dataPath + ".sht.hmd"),
          MessageValue.getValue(message, dataPath + ".sht.tmp"),
        ];
      } else if (sensorName == "psu") {
        sensorValue = [
          MessageValue.getValue(message, dataPath + ".psu.pwr-in"),
          MessageValue.getValue(message, dataPath + ".psu.prot-batt"),
        ];
      } else if (sensorName == "tmp" && App.Topic.isAStatusTopic()) {
        if (message.val["tmp"] != undefined) {
          if (message.val["tmp"]["brd"] != undefined) {
            sensorValue = MessageValue.getValue(message, dataPath + ".tmp.brd");
          } else if (message.val["tmp"]["hst"] != undefined) {
            sensorValue = MessageValue.getValue(message, dataPath + ".tmp.hst");
          }
        }
      } else if (sensorName == "up") {
        if (
          message.val["up"] != undefined &&
          message.val["up"]["load"] != undefined
        ) {
          sensorValue = MessageValue.getValue(
            message,
            dataPath + ".up.load.av5"
          );
        }
      } else if (App.Topic.isSimpleValue(sensorName)) {
        sensorValue = MessageValue.getValue(message, dataPath + "." + valueLabels[sensorName]['dataPath'])
      } else {
        // sensorValue = MessageValue.getValue(message, dataPath)[sensorName][
        //   "cnc"
        // ];
        sensorValue = MessageValue.getValue(message, dataPath + "." + valueLabels[sensorName]['dataPath'] + ".cnc")
      }
    } catch (e) {
      if(App.Topic.DEBUG === true){
        console.log(`Error retrieving value for ${dataPath} and ${sensorName}`);
        console.log(e)  
      }
    }
    //Test displaying null values here
    if (this.getUrlParameter("insertNulls")) {
      if (typeof sensorValue == "number") {
        return this.randomlyNullify(sensorValue);
      } else {
        return [
          this.randomlyNullify(sensorValue[0]),
          this.randomlyNullify(sensorValue[1]),
        ];
      }
    } else {
      return sensorValue;
    }
  }

  static getValue(object, dataPath) {
    try {
      return dataPath.split(".").reduce(MessageValue.indexObject, object);
    } catch (e) {
      if(App.Topic.DEBUG === true){
        console.log(`Error retrieving value for ${dataPath}`);
        console.log(object);
      }
      return null;
    }
  }

  static indexObject(obj, i) {
    return obj[i];
  }

  static getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
}

export default MessageValue;
