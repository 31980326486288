class TopicAutoRefresher {
  constructor(refreshInterval, topic) {
    this.refreshInterval = refreshInterval;
    this.topic = topic;
  }

  // Get messages for 1 millisecond after the currently received most recent message, and now.
  // I.e. only get new messages
  calculateNewTimes() {
    // this.oldStartDate = new Date(
    //   document.querySelector("#start-time > input").value
    // ).toISOString();
    const value = App.Topic.valuesPresent[0];
    //this.oldStartDate = App.Topic.processedMessages[value][0][0][0];
    let timeStamps = App.Topic.processedMessages[value]['reported'].timeStamps
    this.oldStartDate = timeStamps[0];

    // this.oldEndDate = new Date(
    //   document.querySelector("#end-time > input").value
    // ).toISOString();
    
    // this.oldEndDate =
    //   App.Topic.processedMessages[value][0][
    //     App.Topic.processedMessages[value][0].length - 1
    //   ][0];
    this.oldEndDate = timeStamps[timeStamps.length-1];
    
    this.newStartDate = moment(
      this.oldEndDate,
      App.Topic.messageTimeFormatString
    )
      .add(1, "milliseconds")
      .toISOString();

    // this.newEndDate = moment(
    //   this.newStartDate,
    //   App.Topic.messageTimeFormatString
    // )
    //   .add(this.refreshInterval, "seconds")
    //   .toISOString();
    this.newEndDate = new Date().toISOString(); //use now as the end time so that we can catch up if the device went offline for a bit.
  }

  async getMessages() {
    this.setMessagesDuration();

    const apiUrl = "https://aws.southcoastscience.com";

    let retriever = new TopicMessagesRetriever(
      apiUrl,
      this.topic,
      this.newStartDate,
      this.newEndDate
    );
    let messages = await retriever.retrieveMessages();
    MessageProcessor.processMessages(messages.data.Items);
    
    if (messages.data.Items != []) {
      App.Topic.allMessages = App.Topic.allMessages.concat(messages.data.Items);
      this.setTrimTime();
      this.trimProcessedMessages();
    }
    
    TopicTimeTool.setStartDate();
    TopicTimeTool.setStartDatePicker();
    TopicTimeTool.setEndDate();
    TopicTimeTool.setEndDatePicker();

    const gauges = document.querySelectorAll("#charts_div .gauge");
    if (gauges[0] != undefined && gauges[0].style.display == "inline-block") {
      App.Topic.displayGaugesFlag = true;
    } else {
      App.Topic.displayGaugesFlag = false;
    }

    Graph.displayGraphs();
    //App.Topic.displayGraphs();

    if (App.Topic.isAStatusTopic()) {
      App.Topic.displayMap();
    }

    App.Topic.constructAndDisplayShareableUrl();
    if (App.Topic.fetchedLastWrittenData) {
      App.Topic.stopRefreshing();
    }
  }

  constructApiUrl() {
    let uri = "";
    const topic = document.querySelector(".data").getAttribute("data-topic");
    if (App.Topic.isAwsOrg) {
      uri =
        App.Topic.awsApiDomain +
        "/topicMessages?topic=" +
        topic +
        "&startTime=" +
        encodeURIComponent(this.newStartDate) +
        "&endTime=" +
        encodeURIComponent(this.newEndDate) +
        "&checkpoint=auto";
    } else {
      uri =
        App.Topic.apiDomain +
        "/v1/messages/topic/" +
        topic +
        "?start-date=" +
        encodeURIComponent(this.newStartDate) +
        "&end-date=" +
        encodeURIComponent(this.newEndDate);
    }
  }

  trimProcessedMessages() {
    for (let i = 0; i < App.Topic.valuesPresent.length; i++) {
      const value = App.Topic.valuesPresent[i];
      const dataInterpretations = Graph.getDataInterpretationNames();
      for(let d = 0; d < dataInterpretations.length; d++){
        let timeStamps = App.Topic.processedMessages[value][dataInterpretations[d]].timeStamps;
        let readings = App.Topic.processedMessages[value][dataInterpretations[d]].readings;
        for (let ii = 0; ii < timeStamps.length; ii++) {
          try {
            if (new Date(timeStamps[ii]).getTime() < this.trimBeforeTime) {
              timeStamps.splice(ii, 1);
              delete readings[timeStamps[ii]];
            }
          } catch (e) {
            console.log("caught an error" + e);
          }
        }
      }
      
    }
  }

  setMessagesDuration() {
    let aKey = App.Topic.valuesPresent[0];
    let timeStamps = App.Topic.processedMessages[aKey]['reported'].timeStamps;
    let mostRecentMessageTime = new Date(timeStamps[timeStamps.length -1]).getTime();
    let firstMessageTime = new Date(timeStamps[0]).getTime();
    let messagesDuration = mostRecentMessageTime - firstMessageTime;

    this.trimDuration = messagesDuration;
  }

  setTrimTime() {
    let aKey = App.Topic.valuesPresent[0];
    let timeStamps = App.Topic.processedMessages[aKey]['reported'].timeStamps;
    let mostRecentMessageTime = new Date(timeStamps[timeStamps.length -1]).getTime();
    this.trimBeforeTime = mostRecentMessageTime - this.trimDuration;
  }
}
export default TopicAutoRefresher;
