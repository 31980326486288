class TopicMessagesRetriever {
  constructor(apiUri, topic, startTime, endTime) {
    this.apiUri = apiUri;
    this.topic = topic;
    this.startTime = startTime;
    this.endTime = endTime;
    this.timeout = ScsConfig().connectionTimeout;
  }

  async retrieveMessages() {
    const apiKey = document.querySelector(".data").getAttribute("data-api-key");
    axios.defaults.headers.common["Authorization"] = "api-key " + apiKey;
    let urlToCall =
      this.apiUri +
      "/topicMessages?topic=" +
      this.topic +
      "&startTime=" +
      encodeURIComponent(this.startTime) +
      "&endTime=" +
      encodeURIComponent(this.endTime) +
      "&fetchLastWritten=true&checkpoint=auto";
    try {
      const response = await axios.request({
        url: urlToCall,
        timeout: this.timeout
      });
      App.Topic.fetchedLastWrittenData =
        response.data["fetchedLastWrittenData"];
      return response;
    } catch (error) {
      console.log("There was an error retrieving messages");
      console.log(error);
    }
  }
}
export default TopicMessagesRetriever;
