class RegressionCalculator {
  constructor() {}

  predictY(value, x) {
    const values = App.Topic.processedMessages[value][DataInterpretation.getDataInterpretationName()].readings;
        
    let arrayed = [];
    let keys = Object.keys(values);
    for(let i=0; i < keys.length; i++){
      arrayed.push([new Date(keys[i]).getTime(), values[keys[i]]])
    }
    
    const result = regression.linear(arrayed, {
      order: 2,
      precision: 10
    });
    this.slopeValue = result.equation[0];

    return Math.round(result.predict(x)[1]);
  }

  slopeRepresentation() {
    if (this.slopeValue > 0) {
      return "positive";
    } else if (this.slopeValue < 0) {
      return "negative";
    } else {
      return "unchanged";
    }
  }

  getMidPoint(value) {

    const sensorData = App.Topic.processedMessages[value][DataInterpretation.getDataInterpretationName()];
    
    if (sensorData.timeStamps[0] == undefined) {
      return 0;
    }
    // if (App.Topic.messagesWithTimestamps[value][0] == undefined) {
    //   return 0;
    // }
    //const startTime = App.Topic.messagesWithTimestamps[value]['reported'][0][0];
    const startTime = new Date(sensorData.timeStamps[0]).getTime();
    //console.log(`start time is ${startTime}`);
    // const endTime =
    //   App.Topic.messagesWithTimestamps[value]['reported'][
    //     App.Topic.messagesWithTimestamps[value]['reported'].length - 1
    //   ][0];
    const endTime = new Date(sensorData.timeStamps[sensorData.timeStamps.length - 1]).getTime();
    //console.log(`end time is ${endTime}`);
    const midPoint = startTime + (endTime - startTime) / 2;
    return midPoint;
  }
}
export default RegressionCalculator;
