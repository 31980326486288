class MessageFormat {
  constructor() {}

  static version(message) {
    if(message.ver == undefined){
      return 0;
    }
    if(isNaN(parseFloat(message.ver))){
      return 0
    }
    return parseFloat(message.ver);
  }
}
export default MessageFormat;
