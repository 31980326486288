const valueLabels = {
    NO2: {
      label: "NO\u2082",
      longLabel: "Nitrogen dioxide",
      unit: "ppb",
      colour: "#7E391E",
      dataPath: 'NO2',
      graphMinY: 'lte0',
    },
    NO: {
      label: "NO",
      longLabel: "Nitric oxide",
      unit: "ppb",
      colour: "#39646A",
      dataPath: 'NO',
      graphMinY: 'lte0',
    },
    Ox: {
      label: "O\u2083",
      longLabel: "Ozone",
      unit: "ppb",
      colour: "#978664",
      dataPath: 'Ox',
      graphMinY: 'lte0',
    },
    O2: {
      label: "O\u2082",
      unit: "ppb",
      dataPath: 'O2',
      graphMinY: 'lte0',
    },
    SO2: {
      label: "SO\u2082",
      longLabel: "Sulphur dioxide",
      unit: "ppb",
      colour: "#6F2416",
      dataPath: 'SO2',
      graphMinY: 'lte0',
    },
    H2S: {
      label: "H\u2082S",
      longLabel: "Hydrogen sulphide",
      unit: "ppb",
      colour: "#A44E2C",
      dataPath: 'H2S',
      graphMinY: 'lte0',
    },
    CO: {
      label: "CO",
      longLabel: "Carbon monoxide",
      unit: "ppb",
      colour: "#416C3E",
      dataPath: 'CO',
      graphMinY: 'lte0',
    },
    CO2: {
      label: "CO\u2082",
      longLabel: "Carbon dioxide",
      unit: "ppm",
      colour: "#000000",
      dataPath: 'CO2',
      graphMinY: 'lte0',
    },
    VOC: {
      label: "VOC",
      longLabel: "Volatile organic compounds",
      unit: "ppb",
      colour: "#929292",
      graphMinY: 0,
      dataPath: 'VOC',
      graphMinY: 0,
    },
    pt1: {
      label: "pt1",
      unit: "\u00B0C",
      dataPath: 'pt1.tmp'
    },
    hmd: {
      label: "Humidity",
      longLabel: "Relative humidity",
      unit: "%",
      colour: "#1E4C7C",
      graphMinY: 0,
      dataPath: 'hmd',
    },
    tmp: {
      label: "Temp",
      longLabel: "Temperature",
      colour: "#A62B17",
      unit: "\u00B0C",
      dataPath: 'tmp',
    },
    internalTmp: {
      label: "Internal Temperature",
      longLabel: "Internal temp",
      colour: "#A62B17",
      unit: "\u00B0C",
    },
    pA: {
      label: "barometric pressure",
      longLabel: "Actual barometric pressure",
      colour: "#555555",
      unit: "KPa",
      dataPath: 'bar.pA',
      maxY: 'round',
      minY: 'round',
    },    
    pm1: {
      label: "PM\u2081",
      longLabel: "PM\u2081",
      unit: "\u00B5g/m\u00B3",
      colour: "#888888",
      graphMinY: 0,
      dataPath: 'pm1',
    },
    pm2p5: {
      label: "PM\u2082\u002E\u2085",
      longLabel: "PM\u2082\u002E\u2085",
      unit: "\u00B5g/m\u00B3",
      colour: "#555555",
      graphMinY: 0,
      dataPath: 'pm2p5',
    },
    pm10: {
      label: "PM\u2081\u2080",
      longLabel: "PM\u2081\u2080",
      unit: "\u00B5g/m\u00B3",
      colour: "#000000",
      graphMinY: 0,
      dataPath: 'pm10',
    },
    sfr: {
      label: "sfr",
      longLabel: "Sample flow rate",
      unit: "ml/S",
      colour: "#3d97e9",
      graphMinY: 0,
      dataPath: 'sfr',
    },    
    up: {
      label: "up",
      longLabel: "CPU load",
      unit: "",
      colour: "#2F4F4F",
      graphMinY: 0,
      dataPath: 'up'
    },
    "pwr-in": {
      label: "Supply Voltage",
      longLabel: "Supply Voltage",
      unit: "",
      colour: "#27408b",
      graphMinY: 0,
      dataPath: 'psu.pwr-in'
    },
    "prot-batt": {
      label: "Battery Voltage",
      longLabel: "Battery Voltage",
      unit: "",
      colour: "#436eee",
      graphMinY: 0,
      dataPath: 'psu.prott-batt'
    },
    psu: {
      label: "psu",
      unit: "",
      dataPath: 'psu'
    },
    sht: {
      longLabel: "Temperature and relative humidity",
      label: "sht",
      unit: "",
      dataPath: 'sht',
    },
    sig: {
      longLabel: "Modem signal quality",
      label: "sig",
      unit: "%",
      dataPath: 'sig.quality',
      colour: '#888888',
      graphMinY: 0,
      graphMaxY: 110,
    }
  };

  export default valueLabels;